<!--指标库设置  -->
<template>
	<div>
		<div class="child-top">
			<div class="input-from">
				<!-- <el-select class="last-child" clearable placeholder="请选择设备" v-model="deviceSelect" @change="getData">
					<el-option v-for="item in equipmentList" :label="item.name" :value="item.uuid" :key="item.uuid"></el-option>
				</el-select>

				<el-select class="last-child" clearable placeholder="请选择状态" v-model="status" @change="getData">
					<el-option label="已监测" value="1"></el-option>
					<el-option label="未监测" value="0"></el-option>
				</el-select> -->

				<el-date-picker v-model="dateTime" @change="timeChange" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>

				<el-input @keyup.enter.native="getData(true)" class="left-child-input left-child-input-content" clearable suffix-icon="iconfont iconsousuo" placeholder="请输入关键字" v-model="input4">
				</el-input>
				<el-button @click="getData(true)" slot="append" class="searchBtn" round>搜索</el-button>
			</div>
			<div class="operation-button">
				<el-button @click="addDialog" class="new_btn" round>
					<span>新增</span>
				</el-button>
				<el-button @click="print" class="print_btn" round>
					<span>打印</span>
				</el-button>
			</div>
		</div>

		<div class="table_view" style="background: #fff">
			<el-table v-loading="tableLoading" class="content-test-table" :data="tableList" style="width: 100%" :key="'table1'">
				<el-table-column label="序号" prop="order_no" show-overflow-tooltip type="index" width="60" align="center">
					<template slot-scope="scope">{{ (page - 1) * 10 + scope.$index + 1 }}</template>
				</el-table-column>
				<el-table-column label="运动队" prop="department_name" show-overflow-tooltip align="center"></el-table-column>
				<el-table-column label="运动员" prop="staff_name" show-overflow-tooltip align="center"></el-table-column>
				<el-table-column label="样本号" prop="specimen_no" show-overflow-tooltip align="center"></el-table-column>
				<!-- <el-table-column label="监测状态" prop="value" show-overflow-tooltip align="center">
					<template slot-scope="scope">
						{{ scope.row.status ? '已监测' : '未检测' }}
					</template>
				</el-table-column> -->
				<el-table-column label="创建时间" prop="create_time" show-overflow-tooltip align="center"> </el-table-column>
				<!-- <el-table-column label="测试时间" prop="test_time" show-overflow-tooltip align="center"> </el-table-column> -->
				<!-- <el-table-column label="操作" width="120">
					<template slot-scope="scope">
						<el-button class="right20 yellowColor" size="medium" type="text" @click="lookDetail(scope.row)">查看</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<el-pagination
				:current-page.sync="page"
				:page-size="page_size"
				:total="total"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>

		<el-dialog title="新增样本号" center :visible.sync="dialogVisible" width="30%" :show-close="false" :close-on-click-modal="false">
			<!-- <span>这是一段信息</span> -->
			<el-form ref="form" label-position="left" label-width="60px">
				<el-form-item label="运动队" prop="name">
					<el-cascader
						placeholder="请选择运动队"
						v-model="deparmentId"
						:options="deparmentList"
						collapse-tags
						:show-all-levels="false"
						@change="getStaffByStationList"
						:props="{ multiple: true, children: 'children', label: 'name', value: 'uuid', emitPath: false }"
					></el-cascader>
				</el-form-item>
				<el-form-item label="运动员" prop="name">
					<el-select clearable placeholder="请选择人员" v-model="stationUuid" @change="stationChange" multiple collapse-tags>
						<el-option label="全选" value="quanxuan" key="all"></el-option>
						<el-option v-for="(item, index) in stationList" :label="item.name" :value="item.uuid" :key="'staff' + index"></el-option>
					</el-select>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="stationSave">确 定</el-button>
				<el-button @click="stationCancel">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tableLoading: false, //loading
			tableList: [],
			deviceSelect: '',
			page_size: 10,
			total: 0,
			page: 1,
			searchDate: new Date(),
			personList: [],
			personUuid: '',
			GymwareList: [],
			lineData: [],
			barData: [],
			dialogVisible: false,
			deparmentList: [],
			deparmentId: [],
			personUuid: [],
			stationList: [],
			stationUuid: [],
			dateTime: [],
			input4: '',
			status: '',
			equipmentList: [],
		}
	},
	mounted() {
		this.getData()
		this.$axios.post('/p/Gymware/staffLists').then(res => {
			if (res.data.code == 0) {
				this.personUuid = res.data.data[0].athleteReference
				this.personList = res.data.data
			}
		})

		this.$axios.post('/p/staff/tree').then(res => {
			this.deparmentList = res.data.data.department
		})

		this.$axios
			.post(
				'/p/equipment_hardware/listAll',
				this.$qs({
					type: 2,
				})
			)
			.then(res => {
				this.equipmentList = res.data.data.rows
			})
	},

	methods: {
		print() {
			let routeUrl = this.$router.resolve({
				path: '/EquipmentPrint',
				query: {
					deviceSelect: this.deviceSelect,
					status: this.status,
					start_date: this.dateTime ? this.dateTime[0] : '',
					end_date: this.dateTime ? this.dateTime[1] : '',
					name: this.input4,
				},
			})
			window.open(routeUrl.href, '_blank')
		},
		timeChange() {
			console.log(this.dateTime)
			this.getData()
		},
		stationSave() {
			if (!this.stationUuid.length) {
				this.$message({
					type: 'warning',
					message: '请选择运动员',
				})
				return false
			}

			this.$axios
				.post(
					'/p/equipment_record/addSpecimen',
					this.$qs({
						staff_uuid_str: this.stationUuid.toString(''),
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: res.data.message,
						})
						this.stationCancel()
						this.getData()
					}
				})
		},
		stationCancel() {
			this.stationUuid = []
			this.deparmentId = []
			this.stationList = []
			this.dialogVisible = false
		},
		stationChange() {
			if (!this.stationUuid.length) {
				this.stationUuid = []
				return false
			}
			this.stationUuid.forEach(item => {
				if (item == 'quanxuan') {
					var uuids = []
					this.stationList.forEach(itm => {
						uuids.push(itm.uuid)
					})
					this.stationUuid = uuids
				}
			})
		},
		getStaffByStationList() {
			this.$axios
				.post(
					'/p/Staff/getStaffByStation',
					this.$qs({
						department_uuid: this.deparmentId.toString(''),
						type: 'staff',
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.stationList = res.data.data
					}
				})
		},
		addDialog() {
			this.dialogVisible = true
		},
		lookDetail(rows) {
			this.$router.push({
				path: '/DataDocking/EquipmentLook',
				query: {
					uuid: rows.uuid,
					specimen_no: rows.specimen_no,
				},
			})
		},
		getGymData() {
			this.$axios.post('/p/Gymware/syncReps').then(res => {
				if (res.data.code == 0) {
				}
			})
		},
		delData() {
			this.$axios.post('/p/Gymware/delete').then(res => {
				if (res.data.code == 0) {
				}
			})
		},
		selectChange() {},
		setEchartsBar(index, items) {
			var cname = 'echartsBar' + index
			let myChart = this.$echarts.init(document.getElementById(cname))

			var cdata = items.chart_info[0].data,
				x_data = [],
				value_data = []
			for (var i in cdata) {
				x_data.push(i)
				value_data.push(cdata[i])
			}

			var options = {
				title: {
					left: 'center',
					// text: items.chart_info[0].type,
				},
				grid: {
					left: '10%',
					right: '3%',
					top: '10%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					// formatter: '{a}: {c}',
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				color: ['#0055e9'],

				series: [
					{
						data: value_data,
						type: 'bar',
						barWidth: 16,
						name: 'Mean Velocity(m/s)',
					},
				],
			}
			myChart.setOption(options)
		},
		setEchartsLine(index, items) {
			var cname = 'echartsLine' + index
			let myChart = this.$echarts.init(document.getElementById(cname))
			var cdata = items.chart_info[0].data,
				x_data = [],
				value_data = []
			for (var i in cdata) {
				x_data.push(i)
				value_data.push(cdata[i])
			}
			var options = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
					formatter: function(item) {
						return item[0].marker + ' ' + item[0].seriesName + ': ' + item[0].value
					},
				},
				grid: {
					left: '15%',
					right: '3%',
					top: '20%',
					bottom: '15%',
				},
				xAxis: {
					type: 'category',
					data: x_data,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: false,
					},
				},
				yAxis: {
					type: 'value',
					// interval: 1,
					min: 'dataMin',
					// interval: 2,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
					axisLabel: {
						show: true,
						// interval: 2,
					},
				},
				color: ['#0055e9'],
				series: [
					{
						name: 'Mean Power(W)',
						data: value_data,
						type: 'line',
						// symbol: 'circle',
						// symbolSize: 8,
						// borderWidth: 5,
						itemStyle: {
							borderColor: '#0055e9',
						},

						symbolSize: 10,
						symbol: 'circle',
						smooth: true,
						lineStyle: {
							// width: 3,
							// shadowColor: 'rgba(0,0,0,0.3)',
							// shadowBlur: 10,
							// shadowOffsetY: 8,
						},
					},
				],
			}
			myChart.setOption(options)
		},
		setEcharts() {
			this.setEchartsBar(i)
			this.setEchartsLine(i)
		},
		//请求列表数据
		getData() {
			this.$axios
				.post(
					'/p/equipment_record/setLists',
					this.$qs({
						page: this.page,
						page_size: this.page_size,
						start_date: this.dateTime ? this.dateTime[0] : '',
						end_date: this.dateTime ? this.dateTime[1] : '',
						// status: this.status,
						name: this.input4,
						// equipment_uuid: this.deviceSelect,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.tableList = res.data.data.rows
						this.total = res.data.data.total
						this.tableLoading = false
					}
				})
		},
		// 请求gymware数据
		getGymwareData() {
			this.$axios
				.post(
					'/p/Gymware/info',
					this.$qs({
						athleteReference: this.personUuid,
						date: this.searchDate,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.GymwareList = res.data.data.rows
						this.lineData = res.data.data.power_chart
						this.barData = res.data.data.velocity_chart
						this.$nextTick(() => {
							this.lineData.forEach((item, index) => {
								this.setEchartsLine(index, item)
							})
							this.barData.forEach((item, index) => {
								this.setEchartsBar(index, item)
							})
						})
					}
				})
		},

		handleCurrentChange(val) {
			this.page = val
			this.getData()
		},

		getTime(value) {
			const date = new Date(value * 1000)
			const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
			const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
			return hours + ':' + minutes
		},
	},
}
</script>
<style lang="scss" scoped>
.child-top {
	margin-top: 10px;
}
.child-top .el-date-editor {
	border: none;
}
.disColor {
	color: #ccc !important;
}

.text {
	display: inline-block;
	margin-left: 20px;
}

/deep/.el-dialog__title {
	color: #303133;
	font-weight: 700;
}

/deep/.el-input {
	position: relative;
	font-size: 0.72917rem;
	display: inline-block;
	width: 222px;
}

/deep/.el-table th > .cell {
	padding-left: 15px;
}
/deep/.el-pagination__editor.el-input {
	width: 50px;
}

.child-top {
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
		.print_btn {
			background: rgb(53, 200, 203);
		}
	}

	.el-date-editor {
		border-bottom: 2px solid #ccc;
	}
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}

.el-pagination {
	padding-bottom: 10px;
}

.echarts_view {
	overflow-x: auto;
	box-sizing: border-box;
	overflow-y: hidden;
	margin-bottom: 20px;
}

.echarts_content {
	width: 100%;
	height: 300px;
	white-space: nowrap;
	box-sizing: border-box;
	// margin-bottom: 1px;
	div {
		display: inline-block;
		background: #fff;
		margin-right: 10px;
		width: 400px;
		height: 300px;
		box-sizing: border-box;
	}
	.echarts_title {
		width: auto;
		padding: 0 20px;
		text-align: center;
		// line-height: 300px;
		// height: 20px;
		// transform: rotate(-90deg);
		writing-mode: vertical-lr;
		margin-right: 0;
	}
}

.echarts_content_top {
	height: 200px;
	div {
		height: 200px;
	}
}

.table_view {
	margin-bottom: 20px;
}

.el-form {
	/deep/ .el-cascader {
		width: 100%;

		.el-input {
			width: 100%;
		}
	}
	/deep/ .el-select {
		width: 100%;
		.el-input {
			width: 100%;
		}
	}
}
</style>
